<template>
  <m-sidebar-card
    :scope="scope"
    :on-save="save"
    :on-destroy="destroy"
    :saving="$wait.is('saving team')"
    :destroying="$wait.is('destroying team')"
    :is-new="isNew"
    :title="title"
  >
    <v-text-field
      ref="teamNameRef"
      v-model="team.name"
      v-validate="{
        required: true,
      }"
      :error-messages="errors.collect('name', scope)"
      data-vv-validate-on="blur"
      :label="teamNamePlaceholder"
      name="team.name"
      outlined
    />

    <!--<v-checkbox-->
    <!--  ref="isManagerRef"-->
    <!--  v-model="team.isManagerTeam"-->
    <!--  label="Työnjohto"-->
    <!--  class="mt-0 pt-0"-->
    <!--  hide-details-->
    <!--/>-->

    <template v-slot:footer>
      <m-btn-help
        absolute
        :style="{
          right: '24px',
        }"
        class="mt-3"
        @click="() => startTour('MTeamEditor')"
      />
    </template>
  </m-sidebar-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import cloneDeep from 'lodash.clonedeep';
  import { mapWaitingActions } from 'vue-wait';
  import MSidebarCard from './MSidebarCard';
  import { tour } from '@/mixins/tour';

  export default {
    $_veeValidate: { validator: 'new' },

    components: {
      MSidebarCard,
    },

    mixins: [
      tour,
    ],

    data: () => ({
      team: {},
      scope: 'team',
    }),

    computed: {
      ...mapGetters({
        props: 'sidePanel/props',
        teamById: 'projectEditor/teams/teamById',
      }),

      teamId() {
        return this.props.teamId;
      },

      isNew() {
        return this.teamId === null;
      },

      title() {
        if (this.isNew) {
          return this.$pgettext('MTeamEditor component title', 'New team');
        }

        return this.$pgettext('MTeamEditor component title', 'Edit team');
      },

      onDestroy() {
        return this.props.onDestroy;
      },

      teamNamePlaceholder() {
        return this.$pgettext('MTeamEditor component text input placeholder', 'Team name');
      },
    },

    mounted() {
      this.initTeam();
    },

    methods: {
      ...mapActions({
        closeSidePanel: 'sidePanel/closeSidePanel',
      }),

      ...mapWaitingActions('projectEditor/teams', {
        createTeam: 'saving team',
        updateTeam: 'saving team',
        destroyTeam: 'destroying team',
      }),

      initTeam() {
        if (this.isNew) return;
        this.team = cloneDeep(this.teamById(this.teamId));
      },

      async save() {
        const valid = await this.$validator.validateAll(this.scope);

        if (!valid) return;

        if (this.isNew) {
          await this.create();
        } else {
          await this.update();
        }

        this.closeSidePanel();
      },

      async create() {
        const params = {
          team: {
            name: this.team.name,
            isManagerTeam: this.team.isManagerTeam,
          },
        };

        await this.createTeam(params);
      },

      async update() {
        const params = {
          id: this.team.id,
          payload: {
            team: {
              name: this.team.name,
              isManagerTeam: this.team.isManagerTeam,
              order: this.team.order,
            },
          },
        };

        await this.updateTeam(params);
      },

      async destroy() {
        await this.destroyTeam({
          id: this.team.id,
        });

        this.onDestroy();
        this.closeSidePanel();
      },
    },
  };
</script>
